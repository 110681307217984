import React, { useContext, useState } from 'react';
import { Box, Link } from '@material-ui/core'
import { DataContext } from '../../context/DataContext';
import ListDialog from '../ListDialog';

const MotsetsimBranchesPopup = ({ children }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const openDialog = () => {
        setIsDialogOpen(true);
    };
    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    const data = useContext(DataContext);

    return (
        <Box
            style={{
                display: 'inline',
            }}
        >
            <Link
                component="button"
                variant='body1'
                color="textPrimary"
                onClick={(e) => {
                    e.preventDefault();
                    return openDialog();
                }}
                style={{
                    verticalAlign: 'unset',
                }}
            >
                {children}
            </Link>
            <ListDialog open={isDialogOpen} onClose={closeDialog} dataList={data.branches} />
        </Box>
    );
};

export default MotsetsimBranchesPopup;
