import React from 'react';
// Images
import mainBannerImg from '../image/main_banner_kit.png';
// Components
import PageContainer from '../components/PageContainer';
// import KitTopBar from '../components/kit/KitTopBar';
import KitPartners from '../components/kit/KitPartners';

const KitPartnersPage = () => {
    return (
        <PageContainer
            mainBannerSrc={mainBannerImg}
            // topBar={<KitTopBar />}
        >
            <KitPartners />
        </PageContainer>
    );
};

export default KitPartnersPage;
