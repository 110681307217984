import React from 'react';
import { Box, Button, Link, Typography } from '@material-ui/core'
import MotsetsimBranchesPopup from './MotsetsimBranchesPopup';

const MotsetsimIntro = ({ kitLink, kitTarget }) => {
    return (
        <>
            <Box py={1}>
                <Typography display="block" variant="body1" color="primary">
                    שמחים לדאוג לך לערכת הריון ולידה מפנקת ברשת מוצצים ובשיתוף צעדים ראשונים.
                </Typography>
                <Typography display="block" variant="body1" color="primary">
                    מלאי את הפרטים וקבלי קופון בסמס + לתיבת המייל שלך אותו יש להציג באחד מסניפי מוצצים.
                </Typography>
                <Typography display="block" variant="body1" color="primary">
                    ניתן למימוש לנשים בהריון ועד חודש לאחר הלידה, מחיר הערכה ₪29.
                </Typography>
            </Box>
            <Box py={1}>
                <Typography display="block" variant="body1" color="primary">
                    לחצי{' '}
                    <MotsetsimBranchesPopup>
                        לרשימת הסניפים
                    </MotsetsimBranchesPopup>
                    ,{' '}
                    <Link
                        href={kitLink}
                        color="textPrimary"
                        target={kitTarget}
                        rel="noreferrer"
                    >
                        לפירוט תכולת הערכה
                    </Link>.
                </Typography>
            </Box>
            <Box py={1}>
                <Typography display="block" variant="body1" color="primary">
                    השובר הינו למימוש ערכה אחת בלבד בשנה החל מחודש רביעי להריון.
                </Typography>
                <Typography display="block" variant="body1" color="primary">
                    מימוש השובר מותנה בהצטרפות למועדון מוצצים (חינם) בעת מילוי הטופס.
                </Typography>
                <Typography display="block" color="primary" variant="body1">
                    <Button href="/views/resendCoupon" color="primary" variant="contained">
                        יש לך קופון ? לחצי לשחזור קופון קיים
                    </Button>
                </Typography>
            </Box>
        </>
    );
};

export default MotsetsimIntro;
