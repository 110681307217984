import { createContext, useEffect, useState } from 'react'
import firstStepsApi from '../utils/firstStepsApi'

const defaultData = {
    // hospitals: [],
    healthInsurances: [],
    branches: [],
}

export const DataContext = createContext(defaultData)

export function DataProvider({ children }) {
    const [data, setData] = useState(defaultData)

    useEffect(() => {
        const fetch = async () => {
            // const hospitals = await firstStepsApi.getHospitals();
            const healthInsurances = await firstStepsApi.getHealthInsurances()
            const branches = await firstStepsApi.getBranches()
            setData({
                // hospitals,
                healthInsurances,
                branches,
            })
        }
        fetch()
    }, [])

    return (
        <DataContext.Provider value={data}>
            {children}
        </DataContext.Provider>
    )
}
