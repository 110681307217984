import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

//TODO disable street input when city empty

const AutocompleteSearch = ({
   control,
   name,
   label,
   placeholder,
   validateFun,
   error,
   errorText,
   disabled,
   optionsFun,
}) => {
   const [searchTerm, setSearchTerm] = useState('');
   const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
   const [options, setOptions] = useState([]);

   useEffect(() => {
      let isMounted = true;
      !searchTerm && isMounted && setOptions([]);
      const timerId = setTimeout(() => {
         isMounted && setDebouncedSearchTerm(searchTerm);
      }, 400);
      // Cleanup
      return () => {
         isMounted = false;
         clearTimeout(timerId);
      };
   }, [searchTerm]);

   useEffect(() => {
      let isMounted = true;
      const fetch = async () => {
         const options = await optionsFun(debouncedSearchTerm);
         isMounted && setOptions(options);
      };
      debouncedSearchTerm && fetch();
      // Cleanup
      return () => {
         isMounted = false;
      };
   }, [debouncedSearchTerm, optionsFun]);

   return (
      <Controller
         control={control}
         name={name}
         defaultValue=""
         rules={{
            validate: validateFun,
         }}
         render={({ onChange, ...otherProps }) => (
            <Autocomplete
               disabled={disabled}
               options={options}
               getOptionLabel={(option) => option}
               filterOptions={(options) => options}
               freeSolo
               onChange={(e, value) => onChange(value)}
               renderInput={(params) => {
                  return (
                     <TextField
                        {...params}
                        {...otherProps}
                        label={label}
                        placeholder={placeholder}
                        variant="outlined"
                        onChange={(e) => {
                           setSearchTerm(e.target.value);
                           onChange(e.target.value);
                        }}
                        error={error}
                        helperText={error ? errorText : null}
                     />
                  );
               }}
            />
         )}
      />
   );
};

export default AutocompleteSearch;
