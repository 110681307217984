import React from 'react';
import { Box, Link, Typography } from '@material-ui/core'

const KitPartners = () => {
    const items = [
        `רשת מוצצים`,
        `צעדים ראשונים יזמות ושיווק בע"מ`,
        `קימברלי קלארק`,
        `אלפא קוסמטיקה בע"מ`,
        `אן אס אייץ 2012 בע"מ`,
        `ניאושופ שיווק והפצה`,
        `קבוצת ניאופארם`,
        <span>
            מטרנה תעשיות ש.מ (שתעשה שימוש במידע בהתאם למטרות המפורטות ב
            <Link
                href="https://www.materna.co.il/%D7%9E%D7%93%D7%99%D7%A0%D7%99%D7%95%D7%AA-%D7%A4%D7%A8%D7%98%D7%99%D7%95%D7%AA"
                underline="always"
                target="_blank"
            >
                מדיניות הפרטיות
            </Link>
            ) ו/או חברות נוספות מקבוצת אסם-נסטלה שטראוס מים
        </span>,
        `מיננה בע"מ`,
        `שיווק ממוקד ארדו משאבות חלב`,
        `אסנס מדיהקום`,
        `סוכנויות בתחום הביטוח`,
        `הראל חברה לביטוח וסוכני ביטוח הקשורים או עובדים עימה`,
        `וחברות הדם הטבורי' המרכז הארצי לדם טבורי שיבא תל השומר`,
    ];

    return (
        <Box>
            <Typography component="h1" display="block" color="primary" variant="h1">
                רשימת החברות המשתפות פעולה עם רשת מוצצים וצעדים ראשונים
            </Typography>

            {items.map((item, index) => (
                <span>
                    {item}
                    {index < items.length - 1 ? ', ' : ''}
                </span>
            ))}
        </Box>
    );
};

export default KitPartners;
