import React from 'react';
import { Box, Link, Typography } from '@material-ui/core'
import FirstStepsEmailLink from '../FirstStepsEmailLink';

const KitTerms = () => {
    return (
        <Box>
            <Typography component="h1" display="block" color="primary" variant="h1">
                תקנון להרשמות לפעילות ערכת ההריון  של רשת מוצצים ראשל"צ( 2003 ) שותפות מוגבלת ש.ר .  550215453 " וחברת "צעדים ראשונים יזמות ושיווק בע"מ"
            </Typography>
            <Typography display="block" color="primary">
                1. רשת "מוצצים" בשיתוף "צעדים ראשונים יזמות ושיווק בע"מ" מפעילים את ערכות הריון ולידה הנמכרות ברשת מוצצים .
            </Typography>
            <Typography display="block" color="primary">
                2. הפעילות כוללת ערכת הריון ולידה לתקופת ההיריון ועד שבוע לאחר הלידה.
            </Typography>
            <Typography display="block" color="primary">
                3. ההרשמה לפעילות וקבלת הערכה מיועדת לנשים בהריון, מטרימסטר 2 ומעלה בלבד. ועד שבוע לאחר הלידה.
            </Typography>
            <Typography display="block" color="primary">
                4. ההרשמה מתבצעת
                {' '}
                <Link underline="always" href="/kit">
                    בטופס
                </Link>
                {' '}
                המיועד לכך לקבלת הערכה
            </Typography>
            <Typography display="block" color="primary">
                5. כל משתתפת יכולה לממש את הערכה פעם אחת בלבד בשנה .
            </Typography>
            <Typography display="block" color="primary">
                6. הערכה תימסר בסניפי מוצצים בלבד ,באופן אישי לאישה בהריון שנרשמה ובהצגת תעודה מזהה הנושאת את המספר הרשום על גבי שובר הניפוק.
                לא ניתן לקבל את הערכה ע"י בן זוג ו/או כל נציג או שליח של האישה בהריון.
                למעט במקרים חריגים ניתן לפנות במייל
                {' '}
                <FirstStepsEmailLink />
                {' '}
                לקבלת אישור בכתב מיוחד לקבלת הערכה על ידי נציג, או מקרים חריגים אחרים.
            </Typography>
            <Typography display="block" color="primary">
                7. עלות ערכת הריון  וקבלת הערכה 29 ש"ח כולל מע"מ .
            המחירים מתעדכנים מעת לעת. רשת מוצצים ו/או צעדים ראשונים אינם אחראים למחירים המתפרסמים ברשתות החברתיות/אתרים אחרים/קישורים ו/או בכל מקום אחר.
            </Typography>
            <Typography display="block" color="primary">
                8. התשלום יתבצע בסניפי מוצצים. לאחר הצגת קופון מתאים ות.ז
            </Typography>
            <Typography display="block" color="primary">
                9. המחיר הקובע הינו מועד רכישת הערכה בחנות. בשום מקרה מועד הורדת הקופון מדף אתר זה אינו המועד הקובע את מחיר הערכות.
            </Typography>
            <Typography display="block" color="primary">
                10. אימהות לתאומים/שלישיות מקבלות ערכה אחת.
            </Typography>
            <Typography display="block" color="primary">
                11. מלאי הערכות מוגבל בסניפים . קבלת הערכות מותנית במלאי הקיים בסניפים. לכל חודש קאלנדרי יוקצו כ- 2000 ערכות הריון. אין רשת מוצצים ו/או צעדים ראשונים מתחייבים למלאי בכל הסניפים הרשומים. ניתן לקבל את הערכה אך ורק במידה ויש ערכה במלאי.
            </Typography>
            <Typography display="block" color="primary">
                12. קבלת הערכה תעשה רק בסניף מוצצים שנבחר וכרשום על השובר שהופק לקבלת הערכה.
            </Typography>
            <Typography display="block" color="primary">
                13. קבלת הערכה מותנית בהצטרפות למועדון רשת מוצצים בעת מימוש הערכה וללא תשלום נוסף מעבר למחיר הערכה.
                לינק
                {' '}
                <Link underline="always" href="https://www.motsesim.co.il/pages/29629">
                    https://www.motsesim.co.il/pages/29629
                </Link>
            </Typography>
            <Typography display="block" color="primary">
                14. מימוש ההטבה לקבלת הערכה בסניפי מוצצים, יעשה עד למועד התוקף של הקופון שהונפק  וכפוף למלאי הקיים בסניף .
            </Typography>
            <Typography display="block" color="primary">
                15. הרכב תכולת המוצרים ,דוגמיות וההטבות בערכה יכול להשתנות מעת לעת . ומתפרסם בעמוד ההרשמה.
            </Typography>
            <Typography display="block" color="primary">
                16. בערכה מגוון מוצרים עם תוקף משתנה. באחריות המשתמשת לבדוק את התוקף לפני השימוש
            </Typography>
            <Typography component="h2" display="block" color="primary" variant="h2">
                רישום לאתר ערכת הריון ולידה ברשת מוצצים
            </Typography>
            <Typography display="block" color="primary">
                17. לצורך קבלת ערכת הריון ולידה מרשת מוצצים נדרש המשתמש לבצע רישום הכולל מסירת פרטים אישיים. המשתמש נדרש למסור פרטים נכונים ומדוייקים אודותיו. מסירת פרטים אישיים כוזבים מהווה עבירה לפי חוק העונשין, התשל"ז – 1977. לידיעתכם, מסירת פרטים כלשהם הינה בהתאם לרצונכם החופשי בלבד, ואינכם חייבים לעשות כן. עם זאת, אי מסירת הפרטים כאמור, לא תאפשר שימוש בשירותים מסוימים באתר.
            </Typography>
            <Typography display="block" color="primary">
                18. ההרשמה כמנוי מיועדת ומותרת לגילאי ח"י ומעלה בלבד. בהרשמתך הנך מצהיר כי גילך אכן מעל 18 שנה.
            </Typography>
            <Typography display="block" color="primary">
                19. אין לרשום אדם אחר שלא בהסכמתו ו/או ללא נוכחותו מול המסך בשעת הרישום ולאחר שהוסברו לו כל תנאי תקנון זה.
            </Typography>
            <Typography display="block" color="primary">
                20. הרישום באתר הוא לשימוש האישי והבלעדי של המשתמש אשר אינו רשאי להעביר את הרשאת השימוש לאדם אחר כלשהו.
            </Typography>
            <Typography display="block" color="primary">
                21. מפעילי האתר מבצעים מאמצים סבירים בנסיבות העניין כדי להגן על פרטי המשתמש ועל פרטיותו, בהתאם להצהרת הפרטים. יחד עם זאת, בשל אופייה של הרשת, אין אפשרות להבטיח הגנה מלאה על פרטים אלו. לפיכך, המשתמש פוטר את בעלי האתר מכל אחריות לכל נזק ישיר או עקיף, כספי או אחר, שיגרם למשתמש כתוצאה מדליפת פרטים אלו, ללא קשר לסיבת דליפתם.
            </Typography>
            <Typography display="block" color="primary">
                22. לצורך רישום כאמור, יש למסור פרטים אישיים. המידע שנמסר יאוחסן וישמר במאגר מידע ממוחשב הרשום לפי חוק ונועד לשרת את החברה ואת העובדים מולה באופן שוטף לצורך מימוש מטרות המאגר המאושרות בחוק.
            </Typography>
            <Typography display="block" color="primary">
                23. בהרשמה לפעילות ולקבלת הערכה הנרשמת מסכימה כי המידע שנמסר על ידה באמצעות דף ההרשמה ייכלל במאגר המידע של חברת "צעדים ראשונים יזמות ושיווק בע"מ" ו/או " "מוצצים ראשל"צ( 2003 ) שותפות מוגבלת ש.ר .  550215453 " לצורך ניהול הפעילות, הקשר והשירות, לרבות עיבוד המידע ודיוור ישיר למטרות אלו. הנרשמת מודעת ומסכימה לכך כי מסירת המידע תלויה ברצונה ובהסכמתה וכי לא חלה עליה חובה חוקית למסור את המידע, אולם הדבר נדרש לצורך מתן השירותים עבורה. עם זאת שמורה לנרשמת הזכות להימחק ממאגרי המידע הנ"ל בכל עת.
            </Typography>
            <Typography display="block" color="primary">
                24. כן ידוע לי כי מסירת המידע תלויה ברצוני ובהסכמתי וכי לא חלה עלי חובה  חוקית למסור את המידע, אולם הדבר נדרש לצורך מתן השירותים עבורי. עם זאת שמורה לי הזכות להימחק ממאגרי המידע הנ"ל בכל עת.
            </Typography>
            <Typography display="block" color="primary">
                25. ידוע לכל משתמש הנרשם לאתר כי מידע מהמאגר עשוי להימסר לצדדים שלישיים, לחברות המשתתפות בפעילות רשת מוצצים "וצעדים ראשונים" כגון, ובין היתר, קימברלי  קלארק ישראל בע"מ, ניאושופשיווק והפצה ו/או קבוצת ניאופארם, כצט בע"מ, שטראוס מים בע"מ, חברת ל.ד. ישראל אוטו אקוויפמנט 19900 בע"מ, מטרנה תעשיות ש.מ (שתעשה שימוש במידע בהתאם למטרות המפורטות במדיניות הפרטיות) ו/או חברות נוספות מקבוצת אסם-נסטלה, חברות/סוכניות בתחום הביטוח ולרבות הראל חברה לביטוח וסוכני ביטוח הקשורים או עובדים עימה, חברות בתחום הדם הטבורי, חברת "מוצצים", חברת יוניליוור בע"מ, מיננה,ארדו משאבות חלב (להלן יחד: "השותפים העסקיים") שיווק ממוקד חברות בתחום הטלמרקטינג ועשוי להיכלל גם במאגרי המידע שלהם, והמשתמש מאשר קבלת תוכן פרסומי, מידע ופניות שיווקיות ישירות בין היתר באמצעות sms, דוא"ל או בדואר רגיל מחברת צעדים ראשונים ו/או מהשותפים העסקיים.
            </Typography>
            <Typography display="block" color="primary">
                26. ידוע לי שבכל עת אוכל לבטל את הסכמתי לקבלת דיוור פרסומי או פניות שיווקיות מרשת מוצצים ומצעדים ראשונים והחברות המפורטות בסעיף 25 לעיל באמצעות פנייה לכל אחת מהן.
            </Typography>
            <Typography display="block" color="primary">
                27.ידוע לי כי על מנת להסיר את פרטיי מרשימת התפוצה של החברות לעיל, עלי לבקש זאת בנפרד מכל חברה.
            </Typography>
            <Typography display="block" color="primary">
                28.באם אינך מעוניינ/ת להמשיך לקבל דיוור מסוג מסוים או בכלל מטעם חברתנו או
                מטעם גופים הקשורים עימה, אנא שלח/י לנו בכל עת הודעת הסרה / ביטול באחד או
                יותר מהאמצעים הבאים: שליחת מייל לכתובת
                {' '}
                <FirstStepsEmailLink />
                .
                או באמצעות כפתור ההסרה בדיוור הנשלח אלייך.
            </Typography>
            <Typography display="block" color="primary">
                29. הרישום לפעילות מותנה במסירת הפרטים האישים של האישה בהריון בלבד, ובקבלת חומר פרסומי שוטף מצעדים ראשונים ו/או חברת  מוצצים ראשל"צ( 2003 ) שותפות מוגבלת ש.ר .  550215453
            </Typography>
            <Typography display="block" color="primary">
                30. הובהר לי היטב כי המידע שמסרתי יאוחסן וישמר במאגר מידע ממוחשב ומסירת המידע תלויה ברצוני ובהסכמתי וכי לא חלה עלי חובה חוקית למסור את המידע.
                עם זאת שמורה לי הזכות להימחק ממאגר המידע בכל עת ע"י הסרה מהדיוור האלקטרוני הנשלח אלי .  בתחתית הדיוור.
            </Typography>
            <Typography display="block" color="primary">
                31. סמכות השיפוט
            </Typography>
            <Typography display="block" color="primary">
                השימוש באתר, וכל טענה בנוגע לתקנון, לתנאי השימוש ולמדיניות הפרטיות של החברה באתר זה יהיו כפופים אך ורק לחוקי מדינת ישראל.
                בכל מחלוקת הנובעת מתקנון זה או נוגעת לו וכל  טענה ו/או דרישה ו/או תביעה הנוגעת לחברה בקשר לשימוש באתר ובהקשר לשירות הניתן באמצעות האתר תידון בסמכות שיפוט בלעדית של בתי המשפט המוסמכים במחוז תל-אביב בלבד
            </Typography>
        </Box>
    );
};

export default KitTerms;
