import React from 'react';
import { CardMedia, Grid } from '@material-ui/core';

const MainBanner = ({
    src,
}) => {
    return (
        <Grid
            item xs={12}
            style={{
                padding: 0,
            }}>
            <CardMedia
                component="img"
                src={src}
            />
        </Grid>
    );
};

export default MainBanner;
