export const FIRST_NAME = {
   label: 'שם פרטי *',
   placeholder: 'הכניסי שם פרטי',
   errorMessage: 'שדה חובה',
};
export const LAST_NAME = {
   label: 'שם משפחה *',
   placeholder: 'הכניסי שם משפחה',
   errorMessage: 'שדה חובה',
};
export const PHONE_NUMBER = {
   label: 'טלפון נייד *',
   placeholder: '05 -',
   errorMessage: 'הכניסי טלפון נייד תקין',
};
export const CITY = {
   label: 'ישוב *',
   placeholder: 'הכניסי ישוב מהרשימה',
   errorMessage: 'הכניסי ישוב מהרשימה',
};
export const STREET = {
   label: 'רחוב *',
   placeholder: 'הכניסי רחוב מהרשימה',
   errorMessage: 'הכניסי רחוב מהרשימה',
};
export const APARTMENT = {
   label: "מס' בית",
   placeholder: "הכניסי מס' בית",
   errorMessage: '',
};
export const DATE_OF_BIRTH = {
   label: 'תאריך לידה משוער *',
   placeholder: '',
   errorMessage: 'הכניסי תאריך בטווח של החצי שנה הקרובה',
};
export const HOSPITAL = {
   label: 'בית חולים *',
   placeholder: '',
   errorMessage: 'שדה חובה',
};
export const HEALTH_INSURANCES = {
   label: 'קופת חולים',
   placeholder: '',
   errorMessage: '',
};
export const BRANCH = {
   label: 'סניף איסוף הערכה *',
   placeholder: '',
   errorMessage: 'בחרי סניף איסוף',
};
export const CHILDREN_COUNT = {
   label: "מס' ילדים *",
   placeholder: "נא צייני מס' לידה צפוי",
   errorMessage: 'שדה חובה',
};
export const KOSHER = {
   label: 'צריכת מוצרים בכשרות מהדרין',
   options: [
      { title: 'לא', value: 'חילוני' },
      { title: 'כן', value: 'חרדי' },
   ],
   errorMessage: '',
};
