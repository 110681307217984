import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
   listItem: {
      padding: theme.spacing(0),
   },
   listItemIcon: {
      minWidth: '30px',
   },
}));

const ListDialog = ({ open, onClose, dataList }) => {
   const classes = useStyles();
   return (
      <Dialog open={open}>
         <DialogTitle>רשימת סניפים</DialogTitle>
         <DialogContent className={classes.test} dividers>
            <List component="ul" disablePadding={false}>
               {dataList.map((dataItem, index) => (
                  <ListItem key={index} className={classes.listItem}>
                     <ListItemIcon className={classes.listItemIcon}>
                        <StarIcon />
                     </ListItemIcon>
					   <ListItemText primary={dataItem?.title} />
                  </ListItem>
               ))}
            </List>
         </DialogContent>
         <DialogActions>
            <Button autoFocus onClick={onClose}>
               סגירה
            </Button>
         </DialogActions>
      </Dialog>
   );
};

export default ListDialog;
