import React from 'react';
import { Controller } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const PhoneTextField = ({ name, label, placeholder, validateFun, error, helperText, control }) => {
   return (
      <FormControl variant="outlined" error={error}>
         <InputLabel>{label}</InputLabel>
         <Controller
            name={name}
            control={control}
            rules={{
               validate: validateFun,
            }}
            defaultValue=""
            render={(props) => (
               <OutlinedInput
                  {...props}
                  label={label}
                  placeholder={placeholder}
                  inputComponent={MobileMaskedInput}
               />
            )}
         />
         {error ? <FormHelperText id="component-helper-text">{helperText}</FormHelperText> : null}
      </FormControl>
   );
};

export default PhoneTextField;

const MobileMaskedInput = (props) => {
   const { inputRef, ...other } = props;

   return (
      <MaskedInput
         dir="ltr"
         {...other}
         ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
         }}
         mask={['0', '5', /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
         placeholderChar={'\u2000'}
      />
   );
};
