import React from 'react';
// Images
import mainBannerImg from '../image/main_banner_kit.png';
// Components
import { DataProvider } from '../context/DataContext';
import PageContainer from '../components/PageContainer';
// import KitTopBar from '../components/kit/KitTopBar';
import MotsetsimIntro from '../components/motsetsim/MotsetsimIntro';
import KitLegal from '../components/kit/KitLegal';
import CouponFormContainer from '../components/CouponFormContainer';

const KitMainPage = () => {
    const kitLink = '/kit/content';

    const termsLink = process.env.REACT_APP_KIT_TERMS_URL || '/kit/terms';

    return (
        <DataProvider>
            <PageContainer
                mainBannerSrc={mainBannerImg}
                // topBar={<KitTopBar />}
            >
                <CouponFormContainer
                    intro={<MotsetsimIntro kitLink={kitLink} />}
                    legal={<KitLegal />}
                    termsLink={termsLink}
                />
            </PageContainer>
        </DataProvider>
    );
};

export default KitMainPage;
