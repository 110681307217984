import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { createTheme, ThemeProvider, StylesProvider, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MainPage from './pages/MainPage';
import CouponPage from './pages/CouponPage';
import KitMainPage from './pages/KitMainPage';
import KitContentPage from './pages/KitContentPage';
import KitTermsPage from './pages/KitTermsPage';
import KitPartnersPage from './pages/KitPartnersPage';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const fontFamily = ['Poeti', 'Ariel', 'Roboto'].join(',')

const appTheme = createTheme({
    direction: 'rtl',
    typography: {
        fontFamily,
    },
    palette: {
        primary: {
            light: '#8561c5',
            main: '#673ab7',
            dark: '#482880',
            contrastText: '#fff',
        },
        secondary: {
            light: '#dd33fa',
            main: '#d500f9',
            dark: '#9500ae',
            contrastText: '#000',
        },
    },
});

appTheme.typography.h1 = {
    fontSize: '2rem',
    fontFamily,
};

appTheme.typography.h2 = {
    fontSize: '1.6rem',
    fontFamily,
};

const App = () => {
    return (
        <StylesProvider jss={jss}>
            <ThemeProvider theme={appTheme}>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/" component={MainPage} />
                        <Route path="/coupon/:id" component={CouponPage} />
                        <Route exact path="/kit" component={KitMainPage} />
                        <Route path="/kit/content" component={KitContentPage} />
                        <Route path="/kit/terms" component={KitTermsPage} />
                        <Route path="/kit/partners" component={KitPartnersPage} />
                    </Switch>
                </BrowserRouter>
            </ThemeProvider>
        </StylesProvider>
    );
};

export default App;
