import React from 'react';

const ConsentCheckboxContainer = ({ children }) => (
    <div
        className='terms-field'
        style={{ display: 'flex', alignItems: 'center' }}
    >
        {children}
    </div>
);

export default ConsentCheckboxContainer;
