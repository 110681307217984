import React from 'react';
import { Controller } from 'react-hook-form';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const SelectField = ({ control, name, label, options, validateFun, error, errorText }) => {
   return (
      <FormControl variant="outlined" error={error}>
         <InputLabel>{label}</InputLabel>
         <Controller
            control={control}
            name={name}
            defaultValue=""
            rules={{
               validate: validateFun,
            }}
            render={(props) => (
               <Select label={label}  {...props}>
                  {options.map((option, index) => {
                     const isOptionObject = typeof option === 'object';
                     const title = isOptionObject ? option.title : option;
                     const value = isOptionObject ? option.value : option;
                     return (
                        <MenuItem key={index} value={value}>
                           {title}
                        </MenuItem>
                     );
                  })}
               </Select>
            )}
         />
         {error ? <FormHelperText>{errorText}</FormHelperText> : null}
      </FormControl>
   );
};

export default SelectField;
