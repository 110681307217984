import React from 'react';
import { CardMedia, Grid } from '@material-ui/core';
import helpBannerImg from '../../image/help_banner.png';
import infoBannerImg from '../../image/info_banner.jpg';
import WhatsappShareButton from '../WhatsappShareButton';

const MotsetsimSidebar = ({
}) => {
    return (
        <Grid container item xs={12} md={4} spacing={3} alignContent="flex-start" justifyContent="center">
            <Grid item xs={10} sm={12}>
                <CardMedia component="img" src={helpBannerImg} />
            </Grid>
            <Grid item xs={10} sm={12}>
                <WhatsappShareButton
                    message=" קופון לערכת הריון ולידה "
                    url={window.location.href}
                    btnProps={{ fullWidth: true }}
                />
            </Grid>
            <Grid item xs={10} sm={12}>
                <CardMedia component="img" src={infoBannerImg} />
            </Grid>
        </Grid>
    );
};

export default MotsetsimSidebar;
