import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
   root: {
      display: 'flex',
      alignItems: 'center',
   },
   wrapper: {
      position: 'relative',
      width: '100%',
   },
   button: {
      padding: theme.spacing(1, 4),
      background: 'linear-gradient(45deg, #6122aa 30%, #be4ec4 90%)',
      borderRadius: 3,
      border: 0,
      color: 'white',
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
   },
   loader: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
      color: 'white',
   },
}));

const LoaderButton = ({ title, onClick, isLoading, btnProps }) => {
   const classes = useStyles();

   return (
      <div className={classes.root}>
         <div className={classes.wrapper}>
            <Button
               className={classes.button}
               variant="contained"
               disabled={isLoading}
               onClick={onClick}
               {...btnProps}>
               {title}
            </Button>
            {isLoading && <CircularProgress size={24} className={classes.loader} />}
         </div>
      </div>
   );
};

export default LoaderButton;
