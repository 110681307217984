import React,{useEffect} from 'react';
import WhatsApp from '@material-ui/icons/WhatsApp';
import ReactPixel from 'react-facebook-pixel';



export default function WhatsappShareButton({ message, url, btnProps }) {
   useEffect(() => {
	   ReactPixel.init('1420724394631821');
	   ReactPixel.pageView(); // For tracking page view
   }, [])

   return (
      <>
         {/* <!-- Facebook Pixel Code -->
<script>
!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
document,'script','https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1420724394631821');
fbq('track', 'PageView');
</script>
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=1420724394631821&ev=PageView&noscript=1"
/></noscript>
<!-- DO NOT MODIFY -->
<!-- End Facebook Pixel Code --> */}

      </>

   );
}
