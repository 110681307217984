import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Link, Card, CardContent, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// Images
import firstStepsLogo from '../image/first_steps_logo.png';
import motzezimlogo from '../image/motzezim_logo.png';
import FacebookPixel from '../components/FacebookPixel'
import FirstStepsEmailLink from '../components/FirstStepsEmailLink';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    card: {
        padding: theme.spacing(1, 1),
        background: 'linear-gradient(45deg, #c965fc 40%, #8b3f8f 90%)',
    },
}));

const CouponPage = (props) => {
    const { id } = useParams();
    // console.log(props.location.search)
    const errmsg = new URLSearchParams(props.location.search).get("err")

    let err_morethanyear = (id === 'invalid') ? ((errmsg === "lessthanyear") ? true : false) : false //

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <FacebookPixel />
            <Grid
                container
                spacing={10}
                justifyContent="center"
                alignItems="center"
                style={{
                    height: '97vh',
                    margin: '0',
                    width: '100%',
                    overflow: 'hidden',
                }}>
                <Grid item xs={12} md={8} lg={6}>
                    <Card className={classes.card}>
                        <CardContent style={{ textAlign: 'center' }}>
                            <Grid container item xs={12} spacing={3} direction="column">
                                <Grid container item xs={12} direction="row" justifyContent="space-between">
                                    <Grid item xs={5}>
                                        <CardMedia component="img" src={motzezimlogo} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <CardMedia component="img" src={firstStepsLogo} />
                                    </Grid>
                                </Grid>
                                <Grid style={{ color: 'white' }} item xs={12}>
                                    {(err_morethanyear) ? (
                                            <Typography display="block" variant="h5">
                                                ישנה בעיה בהרשמה - נרשמת כבר בשנה האחרונה ולכן לא ניתן להירשם שוב, אך ניתן
                                                <Link underline="always" href="https://coupons.first-steps.co.il/">
                                                    לשחזר קופון קיים{' '}
                                                </Link>
                                            </Typography>
                                        ) :
                                        (
                                            (id === 'invalid') ? (
                                                <Typography display="block" variant="h4">
                                                    טופס לא תקין, הייתה שגיאה נסי שוב בקרוב
                                                </Typography>
                                            ) : (
                                                <Fragment>
                                                    <Typography display="block" variant="h4">
                                                        יופי סיימת!
                                                    </Typography>
                                                    <Typography display="block" variant="body1" gutterBottom={true}>
                                                        {`קופון מס' ${id} נשלח אלייך ברגעים אלה במייל ובהודעת SMS`}
                                                        <br />
                                                        (מומלץ לבדוק גם בתיבת הספאם)
                                                        <br />
                                                        בינתיים הכנו לך שפע של מידע, והטבות בלחיצה כאן &nbsp;
                                                        <Link underline="always" href="https://bit.ly/3EPCL3u">
                                                            הטבות צעדים ראשונים{' '}
                                                        </Link>
                                                    </Typography>
                                                    <Typography display="block" variant="h4">
                                                        שניה לפני שאת סוגרת, בבקשה שימי 💜
                                                    </Typography>
                                                    <Typography display="block" variant="body1" gutterBottom={true}>
                                                        נא להגיע לסניף בזמן ההריון בעצמך עם ת.ז.
                                                        <br />
                                                        לפניות במייל
                                                        <FirstStepsEmailLink />
                                                    </Typography>
                                                    <Typography display="block" variant="h4">
                                                        הצטרפי לסקול!
                                                    </Typography>
                                                    <Typography display="block" variant="body1">
                                                        אנחנו ממש ממליצים לך
                                                        <br />
                                                        להכנס לבית הספר החדש להורות ראשונית
                                                        <br />
                                                        של צעדים ראשונים
                                                        <br />
                                                        הכניסה חופשית
                                                        <br />
                                                        <Link
                                                            underline="always"
                                                            href="https://www.first-steps.co.il/he/first-steps-school"
                                                        >
                                                            https://www.first-steps.co.il/he/first-steps-school
                                                        </Link>
                                                    </Typography>
                                                </Fragment>
                                            )
                                        )}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default CouponPage;
