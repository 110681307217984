import React, { useEffect, useState } from 'react'
import { Box, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import firstStepsApi from '../../utils/firstStepsApi'

const KitContent = () => {
    const [items, setItems] = useState([])

    useEffect(() => {
        const fetch = async () => {
            const contentKitItems = await firstStepsApi.getContentKitItems()
            setItems(contentKitItems)
        }
        fetch()
    }, [])

    return (
        <Box>
            <Typography component="h1" display="block" color="primary" variant="h1">
                תכולת ערכת הריון ולידה מוצצים
            </Typography>

            <List
                style={{
                    listStyleType: 'disc',
                    paddingInlineStart: '20px',
                }}>
                {items.map((item, index) => (
                    <ListItem key={`item-${index}`} style={{ display: 'list-item' }}>
                        <ListItemText primary={item.name} />
                    </ListItem>
                ))}
            </List>
        </Box>
    )
}

export default KitContent
