import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';

const DateOfBirthPicker = ({ name, label, control, validateFun, error, errorText, values }) => {
   return (
      <Controller
         name={name}
         control={control}
         rules={{
            validate: validateFun,
         }}
         defaultValue={values.default}
         as={
            <TextField
               variant="outlined"
               label={label}
               type="date"
               InputLabelProps={{
                  shrink: true,
               }}
               inputProps={{
                  min: values.min,
                  max: values.max,
               }}
               error={error}
               helperText={error ? errorText : null}
            />
         }
      />
   );
};

export default DateOfBirthPicker;
