import React from 'react';
import { Controller } from 'react-hook-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
   icon: {
      color: theme.palette.primary,
   },
}));
const CustomCheckbox = ({ control, name, label, validateFun, error, errorText }) => {
   const classes = useStyles();
   return (
      <FormControl variant="standard" error={error}>
         <FormControlLabel
            control={
               <Controller
                  control={control}
                  name={name}
                  defaultValue={false}
                  rules={{
                     validate: validateFun,
                  }}
                  render={({ onChange, ...otherProps }) => (
                     <Checkbox
                        {...otherProps}
                        onChange={(e) => onChange(e.target.checked)}
                        icon={<FavoriteBorder />}
                        checkedIcon={<Favorite className={classes.icon} />}
                     />
                  )}
               />
            }
            label={label}
         />
         {error ? <FormHelperText>{errorText}</FormHelperText> : null}
      </FormControl>
   );
};

export default CustomCheckbox;
