import { Box, Link, Typography } from '@material-ui/core'
import React from 'react';

const KitLegal = () => {
    return (
        <Box>
            <Typography component="div" display="block" color="primary" variant="body2">
                ניתן ליצור קשר בווצ אפ: 054-2565186
            </Typography>
            <Typography component="div" display="block" color="primary" variant="body2">
                במילוי טופס זה אני מאשר/ת לשמור את פרטיי במאגר המידע של "רשת מוצצים", "צעדים ראשונים" והחברות המשתפות עמה פעולה (רשימת החברות מפורטת להלן).
                אני מאשר/ת שימוש בפרטים גם עבור החברות המשתפות עימה פעולה.
                כמו כן אני מסכימ/ה לקבל/ת פניות שיווקיות, הטבות מידע ופרסומים מרשת "מוצצים" ,מ"צעדים ראשונים" ומהחברות המשתפות עמה פעולה, באמצעי המדיה השונים.
                בין השאר ולא רק באמצעות מסרונים דואר אלקטרוני ובאמצעות התקשרות טלפונית.
                ידוע לי שאוכל להסיר את פרטיי מרשימת התפוצה בכל עת.
                ידוע לי כי על מנת להסיר את פרטיי מרשימת התפוצה של החברות המשתפות פעולה עלי לבקש זאת בנפרד מכל חברה.
            </Typography>
            <Typography component="div" display="block" color="primary" variant="body2">
                לרשימת
                {' '}
                <Link
                    href="/kit/partners"
                    underline="always"
                >
                    החברות המשתפות פעולה
                </Link>
            </Typography>
        </Box>
    );
};

export default KitLegal;
