import axios from 'axios'
import moment from 'moment'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

// TODO ERROR HANDLING

const getCities = async (city) => {
    const res = await axios.get(`${apiBaseUrl}/searchcity`, { params: { city } })
    const cities = res.data
    return cities
}

const getStreets = async (street, city) => {
    const params = { street }
    if (city !== 'אחר') {
        params.city = city
    }
    const res = await axios.get(`${apiBaseUrl}/searchstreet`, { params })
    const streets = [...res.data, 'אחר']
    return streets
}

const getHospitals = async () => {
    const res = await axios.get(`${apiBaseUrl}/GetHospitals`)
    return res.data
}

const getHealthInsurances = async () => {
    const res = await axios.get(`${apiBaseUrl}/GetInsurances`)
    return res.data
}

const getBranches = async () => {
    const res = await axios.get(`${apiBaseUrl}/GetBranches`)
    return res.data
}

const getEmailValidation = async (email) => {
    const res = await axios.get(`${apiBaseUrl}/checkEmail/${email}`)
    return res.data
}

const getIsraeliIdValidation = async (israeliId) => {
    const res = await axios.get(`${apiBaseUrl}/checkID/${israeliId}`)
    return res.data
}

const postUser = async (data, profile) => {
    const res = await axios.post(
        `${apiBaseUrl}/createUser/`,
        data,
        {
            params: {
                profile,
            },
        },
    )
    return res.data
}

const checkExistNumber = async (phoneNumber) => {
    const res = await axios.get(`${apiBaseUrl}/checkNumber/${phoneNumber}`)
    let resData = res.data
    if (!(resData.existing)) return true
    const oldDate = moment(resData.data[0].registration_date2, 'MM/DD/YYYY')
    let difference = moment().diff(oldDate, 'years', true)
    return difference > 1
}

const getContentKitItems = async () => {
    const res = await axios.get(`${apiBaseUrl}/GetContentKitItems`)
    return res.data
}

const firstStepApi = {
    getCities,
    getStreets,
    getHospitals,
    getHealthInsurances,
    getBranches,
    getEmailValidation,
    getIsraeliIdValidation,
    postUser,
    checkExistNumber,
    getContentKitItems,
}

export default firstStepApi
