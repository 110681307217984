import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Link } from '@material-ui/core';
import WhatsApp from '@material-ui/icons/WhatsApp';

const useStyles = makeStyles((theme) => ({
   button: {
      padding: theme.spacing(1, 4),
      background: 'linear-gradient(45deg, #25d366 30%, #2BB140 90%)',
      borderRadius: 3,
      border: 0,
      color: 'white',
      boxShadow: '0 3px 5px 2px rgba(37, 211, 102, .3)',
   },
}));

export default function WhatsappShareButton({ message, url, btnProps }) {
   const classes = useStyles();

   return (
      <Link
         underline="none"
         href={`https://wa.me/?text=${message}${url}`}
         data-action="share/whatsapp/share"
         target="_blank">
         <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<WhatsApp />}
            {...btnProps}>
            שיתוף
         </Button>
      </Link>
   );
}
