import React from 'react';
import HookForm from '../components/HookForm';

const CouponFormContainer = ({
    intro,
    legal,
    termsLink,
}) => {
    return (
        <>
            {!!intro && intro}
            <HookForm
                legal={legal}
                termsLink={termsLink}
            />
        </>
    );
};

export default CouponFormContainer;
