import React from 'react';
import { Grid } from '@material-ui/core';
import FacebookPixel from '../components/FacebookPixel';
import MainBanner from '../components/MainBanner';

const PageContainer = ({
    mainBannerSrc,
    topBar,
    sidebar,
    children,
}) => {
    return (
        <div>
            <FacebookPixel />

            <Grid
                container
                spacing={3}
                justifyContent="center"
                style={{
                    overflow: 'hidden',
                }}>

                {!!topBar && (
                    <Grid
                        item
                        style={{
                            backgroundColor: '#673ab7',
                            flexGrow: 1,
                            padding: 0,
                        }}
                    >
                        {topBar}
                    </Grid>
                )}

                <MainBanner item src={mainBannerSrc} />

                {/** Content */}
                <Grid container item spacing={3} xs={10} justifyContent="center">
                    {/** Main Content - Text, Form */}
                    <Grid item xs={12} md={8}>
                        {children}
                    </Grid>
                    {!!sidebar && sidebar}
                </Grid>
            </Grid>
        </div>
    );
};

export default PageContainer;
