import React from 'react';
import { Link } from '@material-ui/core';

const FirstStepsEmailLink = () => (
    <Link underline="always" href="mailto:info@first-steps.co.il">
        info@first-steps.co.il
    </Link>
);

export default FirstStepsEmailLink;
