import { Link, Typography } from '@material-ui/core';
import React from 'react';

const MotsetsimLegal = () => {
    return (
        <Typography component="div" display="block" color="primary" variant="body2">
            <p>
                ניתן ליצור קשר בטלפון: 1700-550-118
            </p>
            <p>
                בהכנסת פרטייך למאגר המידע של "צעדים ראשונים" הינך מסכימה לכך ש"צעדים ראשונים יזמות ושיווק בע"מ",
                ו/או "רשת מוצצים",
                ו/או "קימברלי קלארק",
                ו/או אלפא קוסמטיקה בע"מ,
                ו/או מיננה בע"מ,
                ו/או אן אס אייצ 2012 בע"מ,
                ו/או ניאושופשיווק והפצה,
                ו/או קבוצת ניאופארם,
                ו/או ארדו משאבות חלב שיווק ממוקד,
                ו/או חברות/סוכנויות בתחום הביטוח,
                ולרבות הראל חברה לביטוח וסוכני ביטוח הקשורים או עובדים עימה,
                וחברות הדם הטבורי או מי מטעמם
                תעשה שימוש בפרטים שלך בהתאם להוראות הדין
                ולקבל הטבות דברי פרסומת
                ו/או תכנים אחרים בדואר אלקטרוני מסרונים או יצירת קשר טלפוני.
            </p>
            <p>
                פרטייך יישמרו גם במאגרי המידע של החברות לעיל בהתאם{' '}
                <Link
                    href="https://www.first-steps.co.il/he/%D7%AA%D7%A7%D7%A0%D7%95%D7%9F-%D7%94%D7%90%D7%AA%D7%A8/"
                    color="textPrimary"
                    target="_blank"
                    rel="noreferrer"
                >
                    לתקנון
                </Link>
                .
            </p>
            <p>
                תוכל/י להסיר את פרטייך מרשימת התפוצה בכל עת.
                ידוע לי כי על מנת להסיר את פרטיי מרשימת התפוצה של החברות לעיל, עלי לבקש זאת בנפרד מכל חברה.
            </p>
        </Typography>
    );
};

export default MotsetsimLegal;
