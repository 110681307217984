import React from 'react';
// Images
import mainBannerImg from '../image/main_banner_kit.png';
// Components
import PageContainer from '../components/PageContainer';
// import KitTopBar from '../components/kit/KitTopBar';
import KitContent from '../components/kit/KitContent';

const KitContentPage = () => {
    return (
        <PageContainer
            mainBannerSrc={mainBannerImg}
            // topBar={<KitTopBar />}
        >
            <KitContent />
        </PageContainer>
    );
};

export default KitContentPage;
