import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { startOfDay, endOfDay, addMonths, subDays, formatISO, parseISO } from 'date-fns';
import { TextField, Typography, Link, Divider, Grid } from '@material-ui/core';
import ReactPixel from 'react-facebook-pixel';
import { DataContext } from '../context/DataContext';
import firstStepApi from '../utils/firstStepsApi';
import helpers from '../utils/helpers';
import { useQuery } from '../hooks/useQuery';
import PhoneTextField from './PhoneTextField';
import AutocompleteSearch from './AutocompleteSearch';
import DateOfBirthPicker from './DateOfBirthPicker';
import SelectField from './SelectField';
import CustomCheckbox from './CustomCheckbox';
import LoaderButton from './LoaderButton';
// import FacebookLikeButton from './FacebookLikeButton';
import {
    FIRST_NAME,
    LAST_NAME,
    CITY,
    STREET,
    APARTMENT,
    PHONE_NUMBER,
    DATE_OF_BIRTH,
    // HOSPITAL,
    HEALTH_INSURANCES,
    BRANCH,
    CHILDREN_COUNT,
    KOSHER,
} from '../utils/constants';
import ConsentCheckboxContainer from './ConsentCheckboxContainer';

const HookForm = ({
    legal,
    termsLink,
}) => {

    const data = useContext(DataContext);

    // Input Field Names
    const dataFieldNames = {
        firstName: 'Firstname',
        lastName: 'Lastname',
        phoneNumber: 'MainPhoneNum',
        city: 'City',
        street: 'Street',
        homeNumber: 'homenum',
        email: 'email',
        emailValidation: 'emailValidation',
        dateOfBirth: 'birthdate',
        childrenCount: 'childrennum',
        kosher: 'mahadrin',
        healthInsurances: 'insurances',
        hospital: 'hospital',
        israeliIdNum: 'idnum',
        storeBranch: 'branch',
        mailList: 'mail_list',
        terms: 'commit_rules',
        motsetsimRegistration: 'confirm_motsetsim_registration',
    };
    // Form Hook
    const { register, control, watch, handleSubmit, errors } = useForm({
        mode: 'onBlur',
    });
    //Watch Input Value
    const { [dataFieldNames.city]: cityValue, [dataFieldNames.email]: emailValue } = watch([
        dataFieldNames.city,
        dataFieldNames.email,
    ]);
    //Dates
    const today = startOfDay(new Date());
    const thirtyDaysAgo = startOfDay(subDays(today, 30));
    const inSixMonth = endOfDay(addMonths(today, 6));

    // style for text
    const fontStyle = {
        color: '#673ab7',
        padding: "10px",
        textAlign: 'center',
        fontWeight: 'bold'
    };

    // Use History Hook
    const history = useHistory();

    const query = useQuery()
    const profile = query.get('profile')
    console.log('profile', profile)

    // Loading indicator - post only
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (data) => {
        setIsLoading(true);
        //post fetch request with fetch

        // await fetch("https://7dff81d36cd91dd9264a0381159f13f6.m.pipedream.net", {
        //    method: 'POST',
        //    headers: {
        //       'Content-Type': 'application/json',
        //    },
        //    body: JSON.stringify(data),
        // });

        console.log('data', data);

        const res = await firstStepApi.postUser(data, profile);
        if (typeof res === 'object') {
            console.log(res);

            setIsLoading(true);
            if (res?.err === "lessthanyear") {
                return history.push('/coupon/invalid?err=lessthanyear');
            } else {
                return history.push('/coupon/invalid');
            }

        }
        let splittedRes = res.split(': ');
        console.log(res);
        setIsLoading(true);

        ReactPixel.fbq('track', 'Lead');

        // //    window.location.replace(`https://coupons.first-steps.co.il/views/thankyou/${splittedRes[1]}`)
        return history.push(`/coupon/${splittedRes[1]}`);
    };

    return (
        <form
            style={{ display: 'grid', gap: '25px', gridTemplateColumns: 'repeat(auto-fill, minmax(100%, 1fr))' }}
            onSubmit={handleSubmit(onSubmit)}>
            <Divider />
            <TextField
                name={dataFieldNames.firstName}
                variant="outlined"
                label={FIRST_NAME.label}
                placeholder={FIRST_NAME.placeholder}
                error={errors.hasOwnProperty(dataFieldNames.firstName)}
                helperText={errors[dataFieldNames.firstName] ? FIRST_NAME.errorMessage : null}
                inputRef={register({ validate: (value) => value.length > 1 })}
            />
            <TextField
                name={dataFieldNames.lastName}
                variant="outlined"
                label={LAST_NAME.label}
                placeholder={LAST_NAME.placeholder}
                error={errors.hasOwnProperty(dataFieldNames.lastName)}
                helperText={errors[dataFieldNames.lastName] ? LAST_NAME.errorMessage : null}
                inputRef={register({ validate: (value) => value.length > 1 })}
            />
            <PhoneTextField
                name={dataFieldNames.phoneNumber}
                label={PHONE_NUMBER.label}
                placeholder={PHONE_NUMBER.placeholder}
                validateFun={async (value) => {
                    const trimmedStr = value.trim();
                    if (trimmedStr.length === 11) {
                        const isExist = await firstStepApi.checkExistNumber(trimmedStr);
                        console.log(isExist)
                        return isExist ? isExist : <>
                            רשומה במערכת בשנה האחרונה, לשיחזור קופון מהשנה האחרונה -
                            <Link
                                href="/views/resendCoupon"
                                color="textPrimary"
                                target="_blank"
                                rel="noreferrer">
                                לחצי
                            </Link>
                        </>
                    } else {
                        return PHONE_NUMBER.errorMessage;
                    }


                }}
                error={errors.hasOwnProperty(dataFieldNames.phoneNumber)}
                helperText={errors[dataFieldNames.phoneNumber] ? errors[dataFieldNames.phoneNumber].message : null}
                control={control}
            />
            <Divider />
            <AutocompleteSearch
                name={dataFieldNames.city}
                label={CITY.label}
                placeholder={CITY.placeholder}
                validateFun={async (value) => {
                    let fixedCity = value.replace(/'/g, "\\'")
                    const cities = await firstStepApi.getCities(fixedCity);
                    return cities.includes(value);
                }}
                error={errors.hasOwnProperty(dataFieldNames.city)}
                errorText={CITY.errorMessage}
                optionsFun={(city) => {
                    let fixedCity = city.replace(/'/g, "\\'")
                    return firstStepApi.getCities(fixedCity);
                }}
                control={control}
            />
            <AutocompleteSearch
                name={dataFieldNames.street}
                label={STREET.label}
                placeholder={STREET.placeholder}
                disabled={errors.hasOwnProperty(dataFieldNames.city) || !cityValue}
                validateFun={async (street) => {
                    let fixedCity = cityValue.replace(/'/g, "\\'")
                    let fixedStreet = street.replace(/'/g, "\\'")
                    const streets = await firstStepApi.getStreets(fixedStreet, fixedCity);
                    return streets.includes(street);
                }}
                error={errors.hasOwnProperty(dataFieldNames.street)}
                errorText={STREET.errorMessage}
                optionsFun={(street) => {
                    let fixedCity = cityValue.replace(/'/g, "\\'")
                    let fixedStreet = street.replace(/'/g, "\\'")
                    return firstStepApi.getStreets(fixedStreet, fixedCity)
                }}
                control={control}
            />
            <TextField
                name={dataFieldNames.homeNumber}
                variant="outlined"
                label={APARTMENT.label}
                placeholder={APARTMENT.placeholder}
                inputRef={register}
            />

            <Divider />
            <TextField
                name={dataFieldNames.email}
                variant="outlined"
                type="email"
                label={'אימייל *'}
                placeholder={'הכניסי כתובת אימייל'}
                error={errors.hasOwnProperty(dataFieldNames.email)}
                helperText={errors[dataFieldNames.email] ? errors[dataFieldNames.email].message : null}
                inputRef={register({
                    validate: {
                        empty: (value) => (value.length ? true : 'שדה חובה'),
                        invalidStructure: (value) => {
                            const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                            return value.match(emailRegex) ? true : 'הכניסי אימייל במבנה תקין';
                        },
                        backendValidation: async (value) => {
                            const emailValidation = await firstStepApi.getEmailValidation(value);
                            let isValid = false;
                            let errorMessage = '';
                            console.log(emailValidation)
                            if (emailValidation.existing) {
                                switch (emailValidation.reason) {
                                    case 'email_accusation':
                                        errorMessage = 'הכתובת קיימת במערכת';
                                        break;
                                    case 'oldness':
                                        errorMessage = 'התקבל קופון עבור כתובת זו, צרי קשר ב-info@first-steps.com';
                                        break;
                                    default:
                                        throw new Error('backend email validation without valid reason');
                                }
                            } else {
                                isValid = true;
                            }
                            return isValid || errorMessage;
                        },
                    },
                })}
            />
            <TextField
                name={dataFieldNames.emailValidation}
                variant="outlined"
                label={'אימות אימייל *'}
                placeholder={'הכניסי כתובת אימייל'}
                disabled={errors.hasOwnProperty(dataFieldNames.email) || !emailValue}
                error={errors.hasOwnProperty(dataFieldNames.emailValidation)}
                helperText={
                    errors[dataFieldNames.emailValidation] ? errors[dataFieldNames.emailValidation].message : null
                }
                inputRef={register({
                    validate: {
                        empty: (value) => value.length || 'שדה חובה',
                        identical: (value) => {
                            const validationEmail = value.trim();
                            const email = emailValue.trim();
                            return validationEmail === email || 'הכניסי אימייל זהה';
                        },
                    },
                })}
            />
            <Divider />
            <DateOfBirthPicker
                name={dataFieldNames.dateOfBirth}
                label={DATE_OF_BIRTH.label}
                validateFun={(value) => {
                    const dateValue = parseISO(value);
                    return dateValue <= inSixMonth && dateValue >= thirtyDaysAgo;
                }}
                values={{
                    min: formatISO(thirtyDaysAgo, { representation: 'date' }),
                    max: formatISO(inSixMonth, { representation: 'date' }),
                    // default: formatISO(today, { representation: 'date' }),
                    default: '',
                }}
                error={errors.hasOwnProperty(dataFieldNames.dateOfBirth)}
                errorText={DATE_OF_BIRTH.errorMessage}
                control={control}
            />
            <TextField
                name={dataFieldNames.childrenCount}
                variant="outlined"
                type="number"
                label={CHILDREN_COUNT.label}
                placeholder={CHILDREN_COUNT.placeholder}
                error={errors.hasOwnProperty(dataFieldNames.childrenCount)}
                helperText={errors[dataFieldNames.childrenCount] ? CHILDREN_COUNT.errorMessage : null}
                inputRef={register({ validate: (value) => parseInt(value) >= 0 })}
                inputProps={{ min: 0 }}
            />
            <Divider />
            {/* <SelectField
            name={dataFieldNames.kosher}
            label={KOSHER.label}
            options={KOSHER.options}
            control={control}
         /> */}
            <SelectField
                name={dataFieldNames.healthInsurances}
                label={HEALTH_INSURANCES.label}
                options={data.healthInsurances}
                control={control}
            />
            {/* <SelectField
            name={dataFieldNames.hospital}
            label={HOSPITAL.label}
            options={data.hospitals}
            validateFun={(value) => value.length > 0}
            error={errors.hasOwnProperty(dataFieldNames.hospital)}
            errorText={HOSPITAL.errorMessage}
			   inputRef={register({
				   validate: {
					   empty: (value) => value.length || 'שדה חובה',
				   }
			   }) }
			    control={control}
         /> */}
            <Divider />
            <TextField
                name={dataFieldNames.israeliIdNum}
                variant="outlined"
                type="number"
                label={'ת.ז. *'}
                placeholder={"הכניסי מס' ת.ז."}
                error={errors.hasOwnProperty(dataFieldNames.israeliIdNum)}
                helperText={
                    errors[dataFieldNames.israeliIdNum] ? errors[dataFieldNames.israeliIdNum].message : null
                }
                inputRef={register({
                    validate: {
                        empty: (value) => value.length || 'שדה חובה',
                        invalid: (value) => {
                            return helpers.isIsraeliIdValid(value) || "מס' ת.ז. לא תקין";
                        },
                        backendValidation: async (value) => {
                            const israeliIdValidation = await firstStepApi.getIsraeliIdValidation(value);
                            return israeliIdValidation.existing ? <>
                                    רשומה במערכת בשנה האחרונה, לשיחזור קופון מהשנה האחרונה -
                                    <Link
                                        href="/views/resendCoupon"
                                        color="textPrimary"
                                        target="_blank"
                                        rel="noreferrer">
                                        לחצי
                                    </Link>
                                </>
                                : true;
                        },
                    },
                })}
            />
            <SelectField
                name={dataFieldNames.storeBranch}
                label={BRANCH.label}
                options={data.branches}
                validateFun={(value) => value > -1}
                error={errors.hasOwnProperty(dataFieldNames.storeBranch)}
                errorText={BRANCH.errorMessage}
                control={control}
            />
            <Divider />
            {!!legal && legal}
            {!!legal && <Divider />}
            {/*<FacebookLikeButton />*/}
            <ConsentCheckboxContainer>
                <CustomCheckbox
                    name={dataFieldNames.mailList}
                    label="ברצוני להירשם לרשימת הדיוור -     הקשר איתך חשוב לנו, ולכן נקפיד לא להציף אותך בספאם. נשלח אלייך רק מידע שבאמת יעניין אותך."
                    validateFun={(value) => {
                        console.log(value);
                        return value
                    }}
                    error={errors.hasOwnProperty(dataFieldNames.mailList)}
                    errorText={'שדה חובה'}
                    control={control}
                />
            </ConsentCheckboxContainer>
            <ConsentCheckboxContainer>
                <CustomCheckbox
                    name={dataFieldNames.terms}
                    label={'קראתי את'}
                    validateFun={(value) => value}
                    error={errors.hasOwnProperty(dataFieldNames.terms)}
                    errorText={'שדה חובה'}
                    control={control}
                />
                <Link
                    href={termsLink}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                        textDecoration: 'underline',
                        fontFamily: 'Poeti,Ariel,Roboto',
                        fontWeight: 'bold',
                    }}
                >
                    התקנון
                </Link>
                .
            </ConsentCheckboxContainer>
            <ConsentCheckboxContainer>
                <CustomCheckbox
                    name={dataFieldNames.motsetsimRegistration}
                    label="אני מאשרת את הצטרפותי ללא עלות למועדון רשת מוצצים."
                    validateFun={(value) => value}
                    error={errors.hasOwnProperty(dataFieldNames.motsetsimRegistration)}
                    errorText={'שדה חובה'}
                    control={control}
                />
                <Link
                    href="https://www.motsesim.co.il/pages/29629"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                        textDecoration: 'underline',
                        fontFamily: 'Poeti,Ariel,Roboto',
                        fontWeight: 'bold',
                    }}
                >
                    תקנון מועדון מוצצים
                </Link>
            </ConsentCheckboxContainer>

            <Divider />

            <Grid container spacing={2} justifyContent="center">
                <LoaderButton
                    title={'כן אני רוצה את ערכת ההריון '}
                    isLoading={isLoading}
                    btnProps={{ type: 'submit' }}
                />
            </Grid>
            <Divider />
            <div style={{
                color: '#673ab7',
                padding: '10px',
                textAlign: 'center',
                fontWeight: 'bold',
                marginTop: '0',
                marginBlockEnd: '1.33em'
            }}>
                <h3 style={fontStyle}>
                    בעת ההרשמה אתם מתחייבים לתקנון.
                </h3>
                שימו לב שיש למלא את כל השדות על מנת לסיים הרשמה.
            </div>
        </form>
    );
};

export default HookForm;
