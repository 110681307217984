import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
// Images
import mainBannerDesktopImg from '../image/main_banner.jpg';
import mainBannerMobileImg from '../image/main_banner_mobile.jpg';
// Components
import { DataProvider } from '../context/DataContext';
import PageContainer from '../components/PageContainer';
import MotsetsimIntro from '../components/motsetsim/MotsetsimIntro';
import MotsetsimSidebar from '../components/motsetsim/MotsetsimSidebar';
import MotsetsimLegal from '../components/motsetsim/MotsetsimLegal';
import CouponFormContainer from '../components/CouponFormContainer';

const MainPage = () => {
    const theme = useTheme();
    const isMobileViewport = useMediaQuery(theme.breakpoints.down('sm'));

    const kitLink = 'https://www.first-steps.co.il/he/%d7%a2%d7%a8%d7%9b%d7%aa-%d7%94%d7%a8%d7%99%d7%95%d7%9f-%d7%9c%d7%99%d7%93%d7%94-%d7%a6%d7%a2%d7%93%d7%99%d7%9d-%d7%a8%d7%90%d7%a9%d7%95%d7%a0%d7%99%d7%9d-%d7%9e%d7%95%d7%a6%d7%a6%d7%99%d7%9d/';
    const termsLink = 'https://www.first-steps.co.il/he/%D7%AA%D7%A7%D7%A0%D7%95%D7%9F-%D7%94%D7%90%D7%AA%D7%A8/'

    return (
        <DataProvider>
            <PageContainer
                mainBannerSrc={isMobileViewport ? mainBannerMobileImg : mainBannerDesktopImg}
                sidebar={<MotsetsimSidebar />}
            >
                <CouponFormContainer
                    intro={<MotsetsimIntro kitLink={kitLink} kitTarget={'_blank'} />}
                    legal={<MotsetsimLegal />}
                    termsLink={termsLink}
                />
            </PageContainer>
        </DataProvider>
    );
};

export default MainPage;
